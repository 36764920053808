<template>
    <div class="bannerBox" :style="{ background: config.bgColor }">
        <div :style="{ margin: config.chooseMargin == 0 ? '0px' : '0 13px' }">
            <div class="img" :style="{
                width: config.chooseMargin == 0 ? '371px' : '345px',
                borderRadius: config.chooseMargin == 0 ? '' : '10px'
            }">
                <div class="imgBox" :id="id">
                    <img :src="config.imgURL" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        config: {
            type: Object,
            default: () => { }
        }
    },
}
</script>
  
<style lang="less" scoped>
.bannerBox {
    width: 100%;
    position: relative;
    padding: 0;

    .img {
        width: 100%;
        overflow: hidden;

        .imgBox {
            display: flex;
            transition: 0.3s;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

}
</style>
  