<template>
  <div class="style_1">
    <div class="content" :style="{ border: `1px solid ${choose_s_color.secondary_color}`, color: config.fontColor }">{{
        config.txt_1
    }}</div>
    <span class="txt_2" :style="{ color: choose_s_color.main_color }">{{ config.txt_2 }}</span>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: () => { }
    },
    choose_s_color: {
      type: Object,
      default: () => { }
    }
  }
}
</script>

<style lang="less" scoped>
.style_1 {
  min-width: 375px;
  padding: 25px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .content {
    flex-shrink: 0;
    width: 36px;
    padding: 10px 10px 33px 10px;
  }

  .txt_2 {
    position: absolute;
    left: 50%;
    bottom: 32px;
    background-color: #fff;
    transform: translateX(-50%);
  }
}
</style>