<template>
  <div class="moveBox" id="customerService">
    <img
      v-if="customerServiceConfig.uploadImgUrl || !customerServiceConfig.logoUploadStyle"
      :src="customerServiceConfig.logoUploadStyle ? customerServiceConfig.uploadImgUrl : customerServiceConfig.defaultImgUrl"
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    customerServiceConfig: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="less" scoped>
.moveBox {
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  width: 68px !important;
  height: 68px !important;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 1px rgba(12, 4, 120, 0.15);
}
</style>
